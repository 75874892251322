<script lang="ts" setup>
const { t } = useT();
const { durationLeft, promoOffer, handleOpenCash } = useExitPopup();

const coins = computed(() => promoOffer.value?.promoOfferPreset?.coins || promoOffer.value?.preset?.coins);
const entries = computed(() => promoOffer.value?.promoOfferPreset?.entries || promoOffer.value?.preset?.entries);
const badgeLabel = computed(() =>
	promoOffer.value?.promoOfferPreset?.badgeLabel ? parseJSON(promoOffer.value.promoOfferPreset.badgeLabel) : {}
);
</script>
<template>
	<div class="timer-offer" @click="() => handleOpenCash('sidebar_exit_timer')">
		<div class="event-head d-flex justify-content-between align-items-start">
			<AText
				v-if="badgeLabel?.percent"
				as="div"
				class="label"
				type="badge-sm"
				:modifiers="['italic', 'uppercase', 'bold']"
			>
				{{ `${badgeLabel.percent} ${badgeLabel?.text ?? ""}` }}
			</AText>
			<div class="timer-box">
				<AText
					v-for="(item, i) in durationLeft"
					:key="i"
					as="div"
					variant="ternopil"
					class="text-coro timer"
					:class="{ spacer: item === ':' }"
					:modifiers="['uppercase', 'bold']"
				>
					<span>{{ item }}</span>
				</AText>
			</div>
		</div>
		<div class="prize-wrap">
			<MPrizeFund v-if="coins" class="prize-entries" variant="coins" icon="12/coins" :icon-size="18">
				<AText class="text-coro" :modifiers="['bold']">
					{{ numberFormat(coins) }}
				</AText>
			</MPrizeFund>
			<MPrizeFund v-if="entries" class="prize-coins" variant="coins" icon="12/secret-coins" :icon-size="18">
				<AText class="text-coro" :modifiers="['bold']">
					{{ numberFormat(entries) }}
				</AText>
				<AText class="text-coro" :modifiers="['uppercase', 'bold']">
					{{ t("For free") }}
				</AText>
			</MPrizeFund>
		</div>
	</div>
</template>
<style lang="scss" scoped>
$gradient-local-1: linear-gradient(180deg, #a667fe 0%, #29055c 100%);

.timer-offer {
	background: $gradient-local-1;
	border-radius: 8px;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	padding: 8px;
}

.event-head {
	width: 100%;
}

.event-wrap {
	min-width: 64px;
}

.label {
	border-radius: 4px;
	padding: 2px 4px;
	line-height: 12px;
	border: 1px solid var(--color-gray-50);
}

.prize-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;

	.prize-entries,
	.prize-coins {
		gap: 2px;
	}
}

.timer-box {
	display: flex;
	align-items: center;
	gap: 2px;
	width: 75px;
}

.timer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 17px;
	height: 20px;
	border-radius: 2px;
	padding: 4px;
	background: var(--overlay-100);
	margin: auto;
}

.spacer {
	width: auto;
	padding: 0;
	background-color: transparent;
}
</style>
